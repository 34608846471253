<template>
  <div id="white-input">
    <div class="withe-input__label">
      {{ label }}
    </div>
    <div class="withe-input-wrapper">
      <label>
        <input type="text" v-model="input" @keypress="isNumber($event)" />
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardNumberInput",
  props: ["label"],
  data() {
    return {
      input: null
    };
  },
  methods: {
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        this.$emit("input", evt.target.value);
        return true;
      }
    }
  }
};
</script>

<style scoped>
#white-input {
  width: 100%;
}

.withe-input-wrapper {
  width: 100%;
}

input {
  width: 100%;
  border: none;
  outline: none;
  border-radius: 5px;
  min-height: 30px;
  padding: 0 10px;
}
</style>
