<template>
  <div id="tips-and-warning">
    <div class="tips-and-warning">
      <div class="tips-and-warning__header">
        نکات و هشدارها
      </div>
      <div class="tips-and-warning__content">
        ز آنجایی که تراکنش های اتریوم غیر قابل بازگشت است، چنانچه آدرس عمومی شخص
        دریافت کننده اعتبار را به اشتباه اعلام کنید،<br /><br />
        تراکنش و اتریوم ارسال شده قابل بازگشت نخواهد بود. بنابراین توصیه می
        کنیم<br />
        پیش از ثبت سفارش، از آدرس عمومی (Public Address) دریافت کننده مطمئن
        شوید.<br /><br />
        به دلیل بار ترافیکی موجود در شبکه اتریوم، شارژ اتریوم ممکن است با تاخیر
        صورت پذیرد.<br />
        خواهشمندیم تا پایان فرآیند شکیبا باشید.<br />
        حساب گیرنده به میزان دلخواه شما با اتریوم و نه ارز های دیگر مانند دلار
        یا یورو شارژ خواهد شد.<br />
        جهت استفاده از خدمات شارژ اتریوم، پیش از هر چیز می بایست نسبت به تایید
        هویت خود در پنل کاربری اکس اونیکس اقدام کنید.<br />
        اطلاعات مربوط به حساب بانکی اعلام شده می بایست با مشخصات کاربری شما
        یکسان باشد در غیر این صورت از انجام تراکنش معذوریم.<br /><br />
        با توجه به غیر قابل برگشت بودن تراکنش اتریوم، امکان لغو تراکنش یا بازگشت
        دادن وجه پس از انجام شارژ اتریوم امکان پذیر نیست.<br /><br />
        از آنجایی که در حال حاضر قانونی در کشور و در رابطه با استفاده از ارز های
        رمزنگاری شده تصویب نشده است، محدودیت در انجام معاملات با استفاده از
        اتریوم وجود ندارد اما چنانچه در آینده قانونی به تصویب برسد،<br /><br />
        موارد را به اطلاع شما خواهیم رساند. به این دلیل که ارز های رمزنگاری شده
        تحت تاثیر برخی از عوامل ممکن است صعود یا سقوط ارزش را به صورت ناگهانی
        تجربه کنند، اکس اونیکس همانگونه که منفعتی در قبال افزایش ارزش آن نمی
        برد، مسئولیتی هم در قبال سقوط قیمت آن نخواهد داشت.<br /><br />
        کارمزد پرداختی از سوی شما در سرعت پردازش تراکنش شما تاثیر خواهد داشت.
        پیشنهاد می کنیم در انتخاب مبلغ تراکنش دقت نمایید چرا که در صورت تعیین
        میزان کارمزد پایین، تایید تراکنش شما ممکن است چند روز زمان ببرد یا حتی
        اصلا صورت نگیرد.<br /><br />
        در صورتی که موجودی اتریوم اکس اونیکس در زمان سفارش کمتر از میزان
        درخواستی شما باشد، تکمیل ظرفیت ممکن است نیازمند زمان باشد و البته در
        صورت بروز تغییر قابل توجه در قیمت اتریوم، موضوع به اطلاع شما خواهد رسید.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TipsAndWarnings"
};
</script>

<style scoped>
.tips-and-warning {
  max-width: 470px;
  min-width: 100%;
  min-height: 758px;
  background: #ffffff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.tips-and-warning__header {
  padding: 10px 10px;
  font-weight: 500;
  font-size: 20px;
  color: #070d59;
}

.tips-and-warning__content {
  padding: 0 10px 10px 10px;
}

@media (min-width: 720px) {
  .tips-and-warning__header {
    padding: 15px 30px;
  }

  .tips-and-warning__content {
    padding: 10px 25px 25px 25px;
  }
}
</style>
