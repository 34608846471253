<template>
  <div id="transfer-to-wallet">
    <div class="transfer-to-wallet-container">
      <div class="transfer-to-wallet__header">
        توضیحات
      </div>
      <div class="transfer-to-wallet__text-area">
        <label>
          <textarea
            class="description-textarea"
            name=""
            id=""
            v-model="description"
          ></textarea>
        </label>
      </div>
      <div class="sell-button-wrapper" @click="$emit('onClick')">
        <button class="sell-button">فروش</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emit: ["modalChang"],
  name: "TransferToWallet",
  props: {
    value: {}
  },
  data() {
    return {
      description: this.value
    };
  },
  watch: {
    description(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.description = newValue;
    }
  },
  // watch: {
  //   description(newValue) {
  //     this.$emit("value", newValue);
  //   }
  // },
  methods: {}
};
</script>

<style scoped>
#transfer-to-wallet {
  margin-top: 20px;
}

.transfer-to-wallet-container {
  font-weight: normal;
  font-size: 16px;
}

.description-textarea {
  outline: none;
  border: none;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 111px;
  resize: none;
}

.sell-button-wrapper {
  width: 100%;
  margin: 10px auto;
}

.sell-button {
  width: 100%;
  padding: 5px 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 31px;
  color: #ffffff;
  background-color: #dc0000;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.sell-button-wrapper:hover {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.28);
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.28);
}

.transfer-to-wallet__header {
  font-family: "Vazir Medium FD";
}

textarea {
  padding: 10px;
}
</style>
