<template>
  <div id="deposit-to-other-account">
    <div class="deposit-to-other-account__wrapper">
      <CardNumberInput label="شماره کارت" @input="handleInputCardNumber" />
      <div class="form-group">
        <WhiteInput
          label="نام و فامیل صاحب حساب"
          @input="handleNameAndFamily"
        />
      </div>
      <div class="form-group">
        <WhiteInput label="نام بانک" @input="handleInputBankName" />
      </div>
      <div class="form-group">
        <ShabaNumberInput label="شماره شبا" @input="handleInputShabaNumber" />
      </div>
      <div class="form-group">
        <CardNumberInput label="شماره حساب" @input="handleInputAccountNumber" />
      </div>
      <div class="form-group">
        <div class="description-section">
          <div class="description-header">توضیحات</div>
          <label>
            <textarea
              class="description-textarea"
              @input="$emit('description', $event.target.value)"
            ></textarea>
          </label>
        </div>
      </div>
      <div class="buy-button-wrapper" @click="$emit('onClick')">
        <button class="sell-button">فروش</button>
      </div>
    </div>
  </div>
</template>

<script>
import CardNumberInput from "@/components/input/CardNumberInput";
import WhiteInput from "@/components/WhiteInput";
import ShabaNumberInput from "@/components/input/ShabaNumberInput";

export default {
  name: "DepositToOthersAccounts",
  components: { ShabaNumberInput, WhiteInput, CardNumberInput },
  methods: {
    handleInputCardNumber(item) {
      this.$emit("cardNumberInput", item);
    },
    handleNameAndFamily(item) {
      this.$emit("nameAndFamilyInput", item);
    },
    handleInputBankName(item) {
      this.$emit("bankNameInput", item);
    },
    handleInputShabaNumber(item) {
      this.$emit("shabaNumber", item);
    },
    handleInputAccountNumber(item) {
      this.$emit("accountNumberInput", item);
    }
  },
  data() {
    return {
      cardNumber: null
    };
  }
};
</script>

<style scoped>
#deposit-to-other-account {
  margin-top: 20px;
}

.form-group {
  margin-top: 20px;
}

.description-textarea {
  outline: none;
  border: none;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 200px;
  resize: none;
}

.description-header {
  font-weight: normal;
  font-size: 16px;
}

.buy-button-wrapper {
  /*max-width: 450px;*/
  margin: 20px auto;
}

.buy-button {
  width: 100%;
  padding: 5px 0;
}

.sell-button {
  width: 100%;
  padding: 5px 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 31px;
  color: #ffffff;
  background-color: #dc0000;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.description-textarea {
  padding: 10px;
}

.buy-button-wrapper:hover {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.28);
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.28);
}
</style>
