<template>
  <div id="white-input">
    <div class="withe-input__label">
      {{ label }}
    </div>
    <div class="withe-input-wrapper">
      <label>
        <input type="text" @input="handleInput" />
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "WhiteInput",
  props: ["label"],
  methods: {
    handleInput(item) {
      this.$emit("input", item.target.value);
    }
  }
};
</script>

<style scoped>
#white-input {
  width: 100%;
}

.withe-input-wrapper {
  width: 100%;
}

input {
  width: 100%;
  border: none;
  outline: none;
  border-radius: 5px;
  min-height: 30px;
  padding: 0 10px;
  direction: ltr;
}
</style>
