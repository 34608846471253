<template>
  <div id="shaba-number-input">
    <div class="shaba-number-input__label">
      شماره شبا
    </div>
    <div class="shaba-number-input__wrapper">
      <div class="ir-wrapper">IR -</div>
      <label>
        <input type="text" @input="handleInput" />
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShabaNumberInput",
  props: ["label"],
  methods: {
    handleInput(item) {
      this.$emit("input", item.target.value);
    }
  }
};
</script>

<style scoped>
#shaba-number-input {
  width: 100%;
}

.shaba-number-input__wrapper {
  direction: ltr;
  display: flex;
  background: #ffff;
  height: 30px;
  border-radius: 5px;
}

.ir-wrapper {
  width: 15%;
  padding: 5px;
  font-weight: normal;
  font-size: 14px;
  color: rgba(18, 18, 18, 0.7);
}

input {
  width: 100%;
  height: 30px;
  padding: 0 5px;
  border: none;
  outline: none;
  border-radius: 5px;
}

label {
  width: 100%;
}

@media (min-width: 720px) {
  .ir-wrapper {
    width: 7%;
  }
}
</style>
