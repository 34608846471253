<template>
  <div id="success-alert">
    <div class="success-alert-container">
      <div class="success-alert__header">
        <img
          src="../../assets/images/ant-design_check-circle-outlined.svg"
          alt=""
          class="success-alert__header-image"
        />
        <div class="success-alert__header-content">
          {{ title }}
        </div>
      </div>
      <div class="success-alert__massage">
        {{ massage }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SuccessAlert",
  props: ["title", "massage"]
};
</script>

<style scoped>
#success-alert {
  position: fixed;
  top: 40%;
}

.success-alert-container {
  max-width: 800px;
  min-height: 226px;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  /*padding: 0 45px;*/

  padding: 5px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  flex-wrap: wrap;
}

.success-alert__header {
  display: flex;
  align-items: center;
}

.success-alert__header-content {
  font-weight: 500;
  /*font-size: 28px;*/
  line-height: 44px;
  color: #121212;
  font-size: 16px;
}

.success-alert__massage {
  font-weight: normal;
  /*font-size: 16px;*/
  line-height: 25px;
  color: #121212;
  font-size: 14px;
}

@media (min-width: 720px) {
  .success-alert-container {
    padding: 0 30px;
  }

  .success-alert__header-content {
    font-size: 28px;
  }

  .success-alert__massage {
    font-size: 16px;
  }
}

@media (min-width: 960px) {
  .success-alert-container {
    padding: 0 45px;
  }
}
</style>
