<template>
  <div id="txid-information" v-if="!close">
    <div class="txid-information__header">
      TxID چیست؟
    </div>
    <div class="content">
      <p>
        لینک تراکنش یا همان TxID یک عبارت 64 کاراکتری بوده که ترکیبی از اعداد و
        حروف است و جهت رهگیری تراکنش استفاده می شود.
      </p>
      <br />
      <p>
        بعنوان مثال: 3fc9153b7bdffcdfae092092320612c9c3c
        94351f600d80ad75f3915909b488b
      </p>
      <br />
      <p>
        ممکن است TxID را کمی با تأخیر دریافت نمایید، لذا پس از انجام تراکنش چند
        دقیقه صبر کرده و پس از دریافت درخواست را ثبت نمایید.
      </p>
      <br />
      <p>
        چنان چه TxID را به درستی وارد نکرده باشید امکان صحت سنجی خودکار وجود
        نخواهد داشت بنابراین ممکن است تأیید درخواست شما بیش از حد معمول زمان
        نیاز داشته باشد.
      </p>
      <br />
      <p>
        دقت نمایید هنگامی که شما انتقال را انجام می دهید، مقداری نیز به عنوان
        کارمزد توسط سرویس دهنده کیف پول شما کسر می شود، اما شما می بایست مقداری
        که هنگام انتقال وارد کرده اید را در بخش فروش به ما وارد نمایید.
      </p>
    </div>
    <div class="button-wrapper">
      <button class="button" @click.prevent="closeModal">
        متوجه شدم
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "TxidInformationModal",
  data() {
    return {
      close: false
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    }
  }
};
</script>

<style scoped>
#txid-information {
  width: 600px;
  height: 560px;
  z-index: 10000;
  position: fixed;
  top: 12%;
  left: 30%;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 25px 30px;
}

.txid-information__header {
  color: #1f3c88;
  font-weight: 500;
  font-size: 20px;
}

p {
  font-weight: normal;
  font-size: 16px;
  color: #121212;
  line-height: 25px;
}

.button {
  background: #1f3c88;
  border-radius: 5px;
  width: 100%;
  padding: 5px;
  color: #ffffff;
  outline: none;
  border: none;
  cursor: pointer;
}

.button-wrapper {
  width: 140px;
  margin: 38px auto;
}
</style>
