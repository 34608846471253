<template>
  <div id="confirm-sales-request-modal">
    <div class="confirm-sales-request-modal__container">
      <div class="confirm-sales-request-modal__header-section">
        <img
          src="../../assets/images/close-icon.svg"
          alt=""
          class="close-icon"
          @click="$emit('close')"
        />
        <div class="confirm-sales-request-modal__header">
          تاییددرخواست فروش
        </div>
      </div>
      <div class="description-of-the-confirm-sales-request">
        درخواست خرید به شرح زیر می باشد:
      </div>
      <div class="buy-details-wrapper">
        <div class="unit-price">
          قیمت واحد:
          <span>
            {{
              item ? Number(item.sale_price_from_us).toLocaleString() : "----"
            }}
          </span>
        </div>
        <div class="amount-of">
          مقدار: <span> {{ amount ? amount : "----" }} </span>
        </div>
        <div class="total-price">
          قیمت کل: <span> {{ total ? total : "----" }} </span>
        </div>
      </div>
      <div class="pay-button-wrapper">
        <button class="button" @click.prevent="closeModal">
          تایید
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmSalesRequest",
  props: {
    item: {},
    amount: {},
    total: {}
  },
  data() {
    return {
      showModal: false
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    }
  }
};
</script>

<style scoped>
#confirm-sales-request-modal {
  width: 100%;
  position: fixed;
  /* top: 25%; */
  /* right: 35%; */
  left: 0;
  top: 0;
  padding: 10px;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.confirm-sales-request-modal__container {
  max-width: 503px;
  min-height: 270px;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgb(0 0 0 / 30%);
  border-radius: 10px;
  padding: 15px;
  width: 100%;
}

.confirm-sales-request-modal__header {
  color: #dc0000;
  font-weight: 500;
  font-size: 20px;
  line-height: 31px;
}

.confirm-sales-request-modal__header-section {
  display: flex;
  align-items: center;
  gap: 30%;
  margin-bottom: 10px;
}

.description-of-the-confirm-sales-request {
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #121212;
}

.buy-details-wrapper div span {
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #121212;
  margin-bottom: 10px;
}

.unit-price {
  padding: 9px 34px 7px 0;
  border-bottom: 1px solid rgba(31, 60, 136, 0.3);
}

.amount-of {
  padding: 9px 34px 7px 0;
  border-bottom: 1px solid rgba(31, 60, 136, 0.3);
}

.total-price {
  padding: 9px 34px 7px 0;
}

.pay-button-wrapper {
  margin: 30px auto;
  display: flex;
}

.button {
  margin: auto;
  border: none;
  outline: none;
  background: #dc0000;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  padding: 4px 45px;
  color: #ffffff;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
}
</style>
