<template>
  <div id="deposit-to-a-bank-account">
    <div class="deposit-to-a-bank-account__wrapper">
      <!--      <BankCardSelect :options="bank_card" />-->
      <BankCardSelect
        title="انتخاب کارت"
        :options="bank_accounts"
        :selectedWhite="true"
        @input="bankSelectedHandler"
      />
      <div class="deposit-to-a-bank-account__description-wrapper">
        <div class="deposit-to-a-bank-account__description-title">
          توضیحات
        </div>
        <div class="deposit-to-a-bank-account__description-textarea-wrapper">
          <label>
            <textarea
              class="description-textarea"
              v-model="description"
            ></textarea>
          </label>
        </div>
      </div>
      <div class="buy-button-wrapper" @click="$emit('onClick')">
        <button class="sell-button">فروش</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import BankCardSelect from "@/components/SelectInput/BankCardSelect";

export default {
  emit: ["modalChang"],
  components: { BankCardSelect },
  data() {
    return {
      bank_accounts: [],
      // bank_account_selected: null,
      description: ""
    };
  },
  mounted() {
    this.getBankAccounts();
  },
  watch: {
    description(newValue) {
      this.$emit("value", newValue);
    }
  },
  methods: {
    bankSelectedHandler(item) {
      this.$emit("bankSelected", item);
    },
    async getBankAccounts() {
      try {
        let response = await axios("/panel/bank-account?status=confirm");
        this.bank_accounts = response.data;
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style scoped>
#deposit-to-a-bank-account {
  margin-top: 20px;
}

.deposit-to-a-bank-account__description-wrapper {
  margin-top: 20px;
}

.description-textarea {
  outline: none;
  border: none;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 200px;
  padding: 10px;
  resize: none;
}

.buy-button-wrapper {
  width: 100%;
  margin-top: 10px;
}

.sell-button {
  width: 100%;
  padding: 5px 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 31px;
  color: #ffffff;
  background-color: #dc0000;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.buy-button-wrapper:hover {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.28);
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.28);
}
</style>
