<template>
  <div id="wallet-balance">
    <div class="wallet-balance-container">
      <img
        src="../../assets/images/wallet-balance-image.svg"
        alt=""
        class="wallet-balance__image"
      />
      <div class="wallet-balance__show-balance">
        <div class="wallet-inventory">
          <div class="wallet-balance-show-balance-title">
            موجودی کیف پول
          </div>
          <div class="wallet-balance-show-balance-balance">
            <span style="font-family: 'Vazir Medium FD'">{{ inventory }}</span>
            تومان
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WalletBalanceCard",
  props: ["inventory"],
  methods: {
    goChargeWallet() {
      console.log("push");
      this.$router.push("/wallet");
    }
  }
};
</script>

<style scoped>
#wallet-balance {
  width: 100%;
}

.wallet-balance-container {
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  gap: 25px;
  flex-wrap: wrap;
}

.wallet-balance__show-balance {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}
.wallet-balance-show-balance-title {
  font-family: "Vazir Medium FD";
}

.wallet-balance-show-balance-balance {
  font-family: "Vazir Medium FD";
}

.wallet-inventory {
  display: flex;
  gap: 10px;
}
.btn {
  display: flex;
  width: 140px;
  height: 36px;
  border-radius: 5px;
  background: #039100;
  color: #ffffff;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
}

/*.btn-icon {*/
/*  width: 13px;*/
/*  height: 13px;*/
/*}*/
</style>
