<template>
  <div id="confirm-purchase-request-modal" v-show="!showModal">
    <div class="confirm-purchase-request-modal__container">
      <div class="confirm-purchase-request-modal__header-section">
        <img
          src="../../assets/images/close-icon.svg"
          alt=""
          class="close-icon"
          @click="closeModal"
        />
        <div class="confirm-purchase-request-modal__header">
          کمبود موجودی
        </div>
      </div>
      <div class="description-of-the-purchase-request">
        درخواست خرید به شرح زیر می باشد و موجودی شما کافی نیست لطفا کیف پول خود
        را شارژ کنید.
      </div>
      <div class="buy-details-wrapper">
        <div class="unit-price">
          قیمت واحد:
          <span> {{ unitPrice ? unitPrice.toLocaleString() : 0 }} </span>
        </div>
        <div class="wrapper">
          <div class="amount-of">
            مقدار: <span> {{ amount }} </span>
          </div>
          <div class="total-price">
            قیمت کل:
            <span> {{ totalPrice ? totalPrice.toLocaleString() : 0 }} </span>
          </div>
        </div>
      </div>
      <div class="wallet-balance-wrapper">
        <WalletBalanceLackOfInventoryCard :inventory="walletInventory" />
      </div>
      <div class="pay-button-wrapper">
        <button class="button" @click.prevent="closeModal">
          تایید
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import WalletBalanceLackOfInventoryCard from "@/components/Cards/WalletBalanceLackOfInventoryCard";

export default {
  name: "LackOfInventory",
  components: { WalletBalanceLackOfInventoryCard },
  props: ["unitPrice", "amount", "totalPrice", "walletInventory"],
  data() {
    return {
      showModal: false
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    }
  }
};
</script>

<style scoped>
#confirm-purchase-request-modal {
  width: 100%;
  position: fixed;
  /* top: 25%; */
  /* right: 35%; */
  left: 0;
  top: 0;
  padding: 10px;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirm-purchase-request-modal__container {
  max-width: 503px;
  min-height: 424px;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 15px;
  width: 100%;
}

.confirm-purchase-request-modal__header {
  font-family: "Vazir Medium FD";
  text-align: center;
  color: #fb9224;
  font-weight: 500;
  font-size: 20px;
  line-height: 31px;
  margin-bottom: 10px;
}

.description-of-the-purchase-request {
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #121212;
}

.confirm-purchase-request-modal__header-section {
  display: flex;
  align-items: center;
  gap: 30%;
  margin-bottom: 10px;
}

.close-icon {
  cursor: pointer;
}

.buy-details-wrapper div span {
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #121212;
  margin-bottom: 10px;
}

.unit-price {
  padding: 9px 34px 7px 0;
  border-bottom: 1px solid rgba(31, 60, 136, 0.3);
}

.wrapper {
  display: flex;
  margin-bottom: 10px;
}

.amount-of {
  padding: 9px 34px 7px 0;
  /*border-bottom: 1px solid rgba(31, 60, 136, 0.3);*/
}

.total-price {
  padding: 9px 34px 7px 0;
}

.pay-button-wrapper {
  margin: 30px auto;
  display: flex;
}

.button {
  margin: auto;
  border: none;
  outline: none;
  background: #fb9224;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  padding: 4px 45px;
  color: #ffffff;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  min-width: 140px;
  max-width: 30px;
}

@media (min-width: 540px) {
  /*#confirm-purchase-request-modal {*/
  /*  top: 25%;*/
  /*  right: 12%;*/
  /*}*/
}

@media (min-width: 960px) {
  /*#confirm-purchase-request-modal {*/
  /*  top: 25%;*/
  /*  right: 30%;*/
  /*}*/
}
</style>
